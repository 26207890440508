export const cc = {
  en: {
    AC: 'Ascension Island',
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua & Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Åland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia & Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Caribbean Netherlands',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo - Kinshasa',
    CF: 'Central African Republic',
    CG: 'Congo - Brazzaville',
    CH: 'Switzerland',
    CI: 'Côte d’Ivoire',
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CP: 'Clipperton Island',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Curaçao',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DG: 'Diego Garcia',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EA: 'Ceuta & Melilla',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands',
    FM: 'Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'So. Georgia & So. Sandwich Isl.',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong (China)',
    HM: 'Heard & McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    IC: 'Canary Islands',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'St. Kitts & Nevis',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'St. Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'St. Martin',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar (Burma)',
    MN: 'Mongolia',
    MO: 'Macau (China)',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'St. Pierre & Miquelon',
    PN: 'Pitcairn Islands',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territories',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'St. Helena',
    SI: 'Slovenia',
    SJ: 'Svalbard & Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'São Tomé & Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syria',
    SZ: 'Swaziland',
    TA: 'Tristan da Cunha',
    TC: 'Turks & Caicos Islands',
    TD: 'Chad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad & Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'U.S. Outlying Islands',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatican City',
    VC: 'St. Vincent & Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VI: 'U.S. Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis & Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },

  de: {
    AC: 'Ascension',
    AD: 'Andorra',
    AE: 'Vereinigte Arabische Emirate',
    AF: 'Afghanistan',
    AG: 'Antigua und Barbuda',
    AI: 'Anguilla',
    AL: 'Albanien',
    AM: 'Armenien',
    AO: 'Angola',
    AQ: 'Antarktis',
    AR: 'Argentinien',
    AS: 'Amerikanisch-Samoa',
    AT: 'Österreich',
    AU: 'Australien',
    AW: 'Aruba',
    AX: 'Ålandinseln',
    AZ: 'Aserbaidschan',
    BA: 'Bosnien und Herzegowina',
    BB: 'Barbados',
    BD: 'Bangladesch',
    BE: 'Belgien',
    BF: 'Burkina Faso',
    BG: 'Bulgarien',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivien',
    BQ: 'Bonaire, Sint Eustatius und Saba',
    BR: 'Brasilien',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvetinsel',
    BW: 'Botsuana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Kanada',
    CC: 'Kokosinseln',
    CD: 'Kongo-Kinshasa',
    CF: 'Zentralafrikanische Republik',
    CG: 'Kongo-Brazzaville',
    CH: 'Schweiz',
    CI: 'Elfenbeinküste',
    CK: 'Cookinseln',
    CL: 'Chile',
    CM: 'Kamerun',
    CN: 'China',
    CO: 'Kolumbien',
    CP: 'Clipperton-Insel',
    CR: 'Costa Rica',
    CU: 'Kuba',
    CV: 'Cabo Verde',
    CW: 'Curaçao',
    CX: 'Weihnachtsinsel',
    CY: 'Zypern',
    CZ: 'Czechia',
    DE: 'Deutschland',
    DG: 'Diego Garcia',
    DJ: 'Dschibuti',
    DK: 'Dänemark',
    DM: 'Dominica',
    DO: 'Dominikanische Republik',
    DZ: 'Algerien',
    EA: 'Ceuta und Melilla',
    EC: 'Ecuador',
    EE: 'Estland',
    EG: 'Ägypten',
    EH: 'Westsahara',
    ER: 'Eritrea',
    ES: 'Spanien',
    ET: 'Äthiopien',
    FI: 'Finnland',
    FJ: 'Fidschi',
    FK: 'Falklandinseln',
    FM: 'Mikronesien',
    FO: 'Färöer',
    FR: 'Frankreich',
    GA: 'Gabun',
    GB: 'Vereinigtes Königreich',
    GD: 'Grenada',
    GE: 'Georgien',
    GF: 'Französisch-Guayana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Grönland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Äquatorialguinea',
    GR: 'Griechenland',
    GS: 'Südgeorgien und die Südlichen Sandwichinseln',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hongkong (China)',
    HM: 'Heard und McDonaldinseln',
    HN: 'Honduras',
    HR: 'Kroatien',
    HT: 'Haiti',
    HU: 'Ungarn',
    IC: 'Kanarische Inseln',
    ID: 'Indonesien',
    IE: 'Irland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'Indien',
    IO: 'Britisches Territorium im Indischen Ozean',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Island',
    IT: 'Italien',
    JE: 'Jersey',
    JM: 'Jamaika',
    JO: 'Jordanien',
    JP: 'Japan',
    KE: 'Kenia',
    KG: 'Kirgisistan',
    KH: 'Kambodscha',
    KI: 'Kiribati',
    KM: 'Komoren',
    KN: 'St. Kitts und Nevis',
    KP: 'Nordkorea',
    KR: 'Südkorea',
    KW: 'Kuwait',
    KY: 'Kaimaninseln',
    KZ: 'Kasachstan',
    LA: 'Laos',
    LB: 'Libanon',
    LC: 'St. Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Litauen',
    LU: 'Luxemburg',
    LV: 'Lettland',
    LY: 'Libyen',
    MA: 'Marokko',
    MC: 'Monaco',
    MD: 'Republik Moldau',
    ME: 'Montenegro',
    MF: 'St. Martin',
    MG: 'Madagaskar',
    MH: 'Marshallinseln',
    MK: 'Mazedonien',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolei',
    MO: 'Macau (China)',
    MP: 'Nördliche Marianen',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Malediven',
    MW: 'Malawi',
    MX: 'Mexiko',
    MY: 'Malaysia',
    MZ: 'Mosambik',
    NA: 'Namibia',
    NC: 'Neukaledonien',
    NE: 'Niger',
    NF: 'Norfolkinsel',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Niederlande',
    NO: 'Norwegen',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Neuseeland',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'Französisch-Polynesien',
    PG: 'Papua-Neuguinea',
    PH: 'Philippinen',
    PK: 'Pakistan',
    PL: 'Polen',
    PM: 'St. Pierre und Miquelon',
    PN: 'Pitcairninseln',
    PR: 'Puerto Rico',
    PS: 'Palästinensische Autonomiegebiete',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Katar',
    RE: 'Réunion',
    RO: 'Rumänien',
    RS: 'Serbien',
    RU: 'Russland',
    RW: 'Ruanda',
    SA: 'Saudi-Arabien',
    SB: 'Salomonen',
    SC: 'Seychellen',
    SD: 'Sudan',
    SE: 'Schweden',
    SG: 'Singapur',
    SH: 'St. Helena',
    SI: 'Slowenien',
    SJ: 'Spitzbergen und Jan Mayen',
    SK: 'Slowakei',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'Südsudan',
    ST: 'São Tomé und Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syrien',
    SZ: 'Swasiland',
    TA: 'Tristan da Cunha',
    TC: 'Turks- und Caicosinseln',
    TD: 'Tschad',
    TF: 'Französische Süd- und Antarktisgebiete',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tadschikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunesien',
    TO: 'Tonga',
    TR: 'Türkei',
    TT: 'Trinidad und Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'Amerikanische Überseeinseln',
    US: 'Vereinigte Staaten',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VA: 'Vatikanstadt',
    VC: 'St. Vincent und die Grenadinen',
    VE: 'Venezuela',
    VG: 'Britische Jungferninseln',
    VI: 'Amerikanische Jungferninseln',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis und Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Jemen',
    YT: 'Mayotte',
    ZA: 'Südafrika',
    ZM: 'Sambia',
    ZW: 'Simbabwe',
  },

  fr: {
    AC: 'Île de l’Ascension',
    AD: 'Andorre',
    AE: 'Émirats arabes unis',
    AF: 'Afghanistan',
    AG: 'Antigua-et-Barbuda',
    AI: 'Anguilla',
    AL: 'Albanie',
    AM: 'Arménie',
    AO: 'Angola',
    AQ: 'Antarctique',
    AR: 'Argentine',
    AS: 'Samoa américaines',
    AT: 'Autriche',
    AU: 'Australie',
    AW: 'Aruba',
    AX: 'Îles Åland',
    AZ: 'Azerbaïdjan',
    BA: 'Bosnie-Herzégovine',
    BB: 'Barbade',
    BD: 'Bangladesh',
    BE: 'Belgique',
    BF: 'Burkina Faso',
    BG: 'Bulgarie',
    BH: 'Bahreïn',
    BI: 'Burundi',
    BJ: 'Bénin',
    BL: 'Saint-Barthélemy',
    BM: 'Bermudes',
    BN: 'Brunéi Darussalam',
    BO: 'Bolivie',
    BQ: 'Pays-Bas caribéens',
    BR: 'Brésil',
    BS: 'Bahamas',
    BT: 'Bhoutan',
    BV: 'Île Bouvet',
    BW: 'Botswana',
    BY: 'Biélorussie',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Îles Cocos',
    CD: 'Congo-Kinshasa',
    CF: 'République centrafricaine',
    CG: 'Congo-Brazzaville',
    CH: 'Suisse',
    CI: 'Côte d’Ivoire',
    CK: 'Îles Cook',
    CL: 'Chili',
    CM: 'Cameroun',
    CN: 'Chine',
    CO: 'Colombie',
    CP: 'Île Clipperton',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cap-Vert',
    CW: 'Curaçao',
    CX: 'Île Christmas',
    CY: 'Chypre',
    CZ: 'Tchéquie',
    DE: 'Allemagne',
    DG: 'Diego Garcia',
    DJ: 'Djibouti',
    DK: 'Danemark',
    DM: 'Dominique',
    DO: 'République dominicaine',
    DZ: 'Algérie',
    EA: 'Ceuta et Melilla',
    EC: 'Équateur',
    EE: 'Estonie',
    EG: 'Égypte',
    EH: 'Sahara occidental',
    ER: 'Érythrée',
    ES: 'Espagne',
    ET: 'Éthiopie',
    FI: 'Finlande',
    FJ: 'Fidji',
    FK: 'Îles Malouines',
    FM: 'États fédérés de Micronésie',
    FO: 'Îles Féroé',
    FR: 'France',
    GA: 'Gabon',
    GB: 'Royaume-Uni',
    GD: 'Grenade',
    GE: 'Géorgie',
    GF: 'Guyane française',
    GG: 'Guernesey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenland',
    GM: 'Gambie',
    GN: 'Guinée',
    GP: 'Guadeloupe',
    GQ: 'Guinée équatoriale',
    GR: 'Grèce',
    GS: 'Géorgie du Sud et îles Sandwich du Sud',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinée-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong (Chine)',
    HM: 'Îles Heard et McDonald',
    HN: 'Honduras',
    HR: 'Croatie',
    HT: 'Haïti',
    HU: 'Hongrie',
    IC: 'Îles Canaries',
    ID: 'Indonésie',
    IE: 'Irlande',
    IL: 'Israël',
    IM: 'Île de Man',
    IN: 'Inde',
    IO: 'Territoire britannique de l’océan Indien',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Islande',
    IT: 'Italie',
    JE: 'Jersey',
    JM: 'Jamaïque',
    JO: 'Jordanie',
    JP: 'Japon',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KH: 'Cambodge',
    KI: 'Kiribati',
    KM: 'Comores',
    KN: 'Saint-Christophe-et-Niévès',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    KW: 'Koweït',
    KY: 'Îles Caïmans',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Liban',
    LC: 'Sainte-Lucie',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesotho',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    LV: 'Lettonie',
    LY: 'Libye',
    MA: 'Maroc',
    MC: 'Monaco',
    MD: 'Moldavie',
    ME: 'Monténégro',
    MF: 'Saint-Martin',
    MG: 'Madagascar',
    MH: 'Îles Marshall',
    MK: 'Macédoine',
    ML: 'Mali',
    MM: 'Myanmar (Birmanie)',
    MN: 'Mongolie',
    MO: 'Macau (Chine)',
    MP: 'Îles Mariannes du Nord',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MS: 'Montserrat',
    MT: 'Malte',
    MU: 'Maurice',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexique',
    MY: 'Malaisie',
    MZ: 'Mozambique',
    NA: 'Namibie',
    NC: 'Nouvelle-Calédonie',
    NE: 'Niger',
    NF: 'Île Norfolk',
    NG: 'Nigéria',
    NI: 'Nicaragua',
    NL: 'Pays-Bas',
    NO: 'Norvège',
    NP: 'Népal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nouvelle-Zélande',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Pérou',
    PF: 'Polynésie française',
    PG: 'Papouasie-Nouvelle-Guinée',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Pologne',
    PM: 'Saint-Pierre-et-Miquelon',
    PN: 'Îles Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Territoires palestiniens',
    PT: 'Portugal',
    PW: 'Palaos',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'La Réunion',
    RO: 'Roumanie',
    RS: 'Serbie',
    RU: 'Russie',
    RW: 'Rwanda',
    SA: 'Arabie saoudite',
    SB: 'Îles Salomon',
    SC: 'Seychelles',
    SD: 'Soudan',
    SE: 'Suède',
    SG: 'Singapour',
    SH: 'Sainte-Hélène',
    SI: 'Slovénie',
    SJ: 'Svalbard et Jan Mayen',
    SK: 'Slovaquie',
    SL: 'Sierra Leone',
    SM: 'Saint-Marin',
    SN: 'Sénégal',
    SO: 'Somalie',
    SR: 'Suriname',
    SS: 'Soudan du Sud',
    ST: 'Sao Tomé-et-Principe',
    SV: 'Salvador',
    SX: 'Saint-Martin (partie néerlandaise)',
    SY: 'Syrie',
    SZ: 'Swaziland',
    TA: 'Tristan da Cunha',
    TC: 'Îles Turques-et-Caïques',
    TD: 'Tchad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thaïlande',
    TJ: 'Tadjikistan',
    TK: 'Tokélaou',
    TL: 'Timor oriental',
    TM: 'Turkménistan',
    TN: 'Tunisie',
    TO: 'Tonga',
    TR: 'Turquie',
    TT: 'Trinité-et-Tobago',
    TV: 'Tuvalu',
    TW: 'Taïwan',
    TZ: 'Tanzanie',
    UA: 'Ukraine',
    UG: 'Ouganda',
    UM: 'Îles mineures éloignées des É.-U.',
    US: 'États-Unis',
    UY: 'Uruguay',
    UZ: 'Ouzbékistan',
    VA: 'État de la Cité du Vatican',
    VC: 'Saint-Vincent-et-les-Grenadines',
    VE: 'Venezuela',
    VG: 'Îles Vierges britanniques',
    VI: 'Îles Vierges des États-Unis',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis-et-Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yémen',
    YT: 'Mayotte',
    ZA: 'Afrique du Sud',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
  },

  es: {
    AC: 'Isla de la Ascensión',
    AD: 'Andorra',
    AE: 'Emiratos Árabes Unidos',
    AF: 'Afganistán',
    AG: 'Antigua y Barbuda',
    AI: 'Anguila',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antártida',
    AR: 'Argentina',
    AS: 'Samoa Americana',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Islas Åland',
    AZ: 'Azerbaiyán',
    BA: 'Bosnia y Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladés',
    BE: 'Bélgica',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Baréin',
    BI: 'Burundi',
    BJ: 'Benín',
    BL: 'San Bartolomé',
    BM: 'Bermudas',
    BN: 'Brunéi',
    BO: 'Bolivia',
    BQ: 'Caribe neerlandés',
    BR: 'Brasil',
    BS: 'Bahamas',
    BT: 'Bután',
    BV: 'Isla Bouvet',
    BW: 'Botsuana',
    BY: 'Bielorrusia',
    BZ: 'Belice',
    CA: 'Canadá',
    CC: 'Islas Cocos',
    CD: 'República Democrática del Congo',
    CF: 'República Centroafricana',
    CG: 'República del Congo',
    CH: 'Suiza',
    CI: 'Costa de Marfil',
    CK: 'Islas Cook',
    CL: 'Chile',
    CM: 'Camerún',
    CN: 'China',
    CO: 'Colombia',
    CP: 'Isla Clipperton',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CW: 'Curazao',
    CX: 'Isla de Navidad',
    CY: 'Chipre',
    CZ: 'Chequia',
    DE: 'Alemania',
    DG: 'Diego García',
    DJ: 'Yibuti',
    DK: 'Dinamarca',
    DM: 'Dominica',
    DO: 'República Dominicana',
    DZ: 'Argelia',
    EA: 'Ceuta y Melilla',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egipto',
    EH: 'Sáhara Occidental',
    ER: 'Eritrea',
    ES: 'España',
    ET: 'Etiopía',
    FI: 'Finlandia',
    FJ: 'Fiyi',
    FK: 'Islas Malvinas',
    FM: 'Micronesia',
    FO: 'Islas Feroe',
    FR: 'Francia',
    GA: 'Gabón',
    GB: 'Reino Unido',
    GD: 'Granada',
    GE: 'Georgia',
    GF: 'Guayana Francesa',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenlandia',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadalupe',
    GQ: 'Guinea Ecuatorial',
    GR: 'Grecia',
    GS: 'Islas Georgia del Sur y Sandwich del Sur',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bisáu',
    GY: 'Guyana',
    HK: 'Hong Kong (China)',
    HM: 'Islas Heard y McDonald',
    HN: 'Honduras',
    HR: 'Croacia',
    HT: 'Haití',
    HU: 'Hungría',
    IC: 'Canarias',
    ID: 'Indonesia',
    IE: 'Irlanda',
    IL: 'Israel',
    IM: 'Isla de Man',
    IN: 'India',
    IO: 'Territorio Británico del Océano Índico',
    IQ: 'Irak',
    IR: 'Irán',
    IS: 'Islandia',
    IT: 'Italia',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordania',
    JP: 'Japón',
    KE: 'Kenia',
    KG: 'Kirguistán',
    KH: 'Camboya',
    KI: 'Kiribati',
    KM: 'Comoras',
    KN: 'San Cristóbal y Nieves',
    KP: 'Corea del Norte',
    KR: 'Corea del Sur',
    KW: 'Kuwait',
    KY: 'Islas Caimán',
    KZ: 'Kazajistán',
    LA: 'Laos',
    LB: 'Líbano',
    LC: 'Santa Lucía',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lituania',
    LU: 'Luxemburgo',
    LV: 'Letonia',
    LY: 'Libia',
    MA: 'Marruecos',
    MC: 'Mónaco',
    MD: 'Moldavia',
    ME: 'Montenegro',
    MF: 'San Martín',
    MG: 'Madagascar',
    MH: 'Islas Marshall',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar (Birmania)',
    MN: 'Mongolia',
    MO: 'Macau (China)',
    MP: 'Islas Marianas del Norte',
    MQ: 'Martinica',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauricio',
    MV: 'Maldivas',
    MW: 'Malaui',
    MX: 'México',
    MY: 'Malasia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'Nueva Caledonia',
    NE: 'Níger',
    NF: 'Isla Norfolk',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Países Bajos',
    NO: 'Noruega',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nueva Zelanda',
    OM: 'Omán',
    PA: 'Panamá',
    PE: 'Perú',
    PF: 'Polinesia Francesa',
    PG: 'Papúa Nueva Guinea',
    PH: 'Filipinas',
    PK: 'Pakistán',
    PL: 'Polonia',
    PM: 'San Pedro y Miquelón',
    PN: 'Islas Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Territorios Palestinos',
    PT: 'Portugal',
    PW: 'Palaos',
    PY: 'Paraguay',
    QA: 'Catar',
    RE: 'Reunión',
    RO: 'Rumanía',
    RS: 'Serbia',
    RU: 'Rusia',
    RW: 'Ruanda',
    SA: 'Arabia Saudí',
    SB: 'Islas Salomón',
    SC: 'Seychelles',
    SD: 'Sudán',
    SE: 'Suecia',
    SG: 'Singapur',
    SH: 'Santa Elena',
    SI: 'Eslovenia',
    SJ: 'Svalbard y Jan Mayen',
    SK: 'Eslovaquia',
    SL: 'Sierra Leona',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    SS: 'Sudán del Sur',
    ST: 'Santo Tomé y Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Siria',
    SZ: 'Suazilandia',
    TA: 'Tristán de Acuña',
    TC: 'Islas Turcas y Caicos',
    TD: 'Chad',
    TF: 'Territorios Australes Franceses',
    TG: 'Togo',
    TH: 'Tailandia',
    TJ: 'Tayikistán',
    TK: 'Tokelau',
    TL: 'Timor Oriental',
    TM: 'Turkmenistán',
    TN: 'Túnez',
    TO: 'Tonga',
    TR: 'Turquía',
    TT: 'Trinidad y Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwán',
    TZ: 'Tanzania',
    UA: 'Ucrania',
    UG: 'Uganda',
    UM: 'Islas menores alejadas de EE. UU.',
    US: 'Estados Unidos',
    UY: 'Uruguay',
    UZ: 'Uzbekistán',
    VA: 'Ciudad del Vaticano',
    VC: 'San Vicente y las Granadinas',
    VE: 'Venezuela',
    VG: 'Islas Vírgenes Británicas',
    VI: 'Islas Vírgenes de EE. UU.',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis y Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'Sudáfrica',
    ZM: 'Zambia',
    ZW: 'Zimbabue',
  },

  it: {
    AC: "Isola dell'ascensione",
    AD: 'Andorra',
    AE: 'Emirati Arabi Uniti',
    AF: 'Afghanistan',
    AG: 'Antigua e Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antartide',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Åland Islands',
    AZ: 'Azerbaigian',
    BA: 'Bosnia ed Erzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgio',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrein',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Paesi Bassi caraibici',
    BR: 'Brasile',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Isola Bouvet',
    BW: 'Botswana',
    BY: 'Bielorussia',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Isole Cocos',
    CD: 'Congo - Kinshasa',
    CF: 'Repubblica Centrafricana',
    CG: 'Congo - Brazzaville',
    CH: 'Svizzera',
    CI: 'Costa d’Avorio',
    CK: 'Isole Cook',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'Cile',
    CO: 'Colombia',
    CP: 'Clipperton Island',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Capo Verde',
    CW: 'Curacao',
    CX: 'Isola di Natale',
    CY: 'Cipro',
    CZ: 'Repubblica Ceca',
    DE: 'Germania',
    DG: 'Diego Garcia',
    DJ: 'Gibuti',
    DK: 'Danimarca',
    DM: 'Dominica',
    DO: 'Repubblica Dominicana',
    DZ: 'Algeria',
    EA: 'Ceuta e Melilla',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egitto',
    EH: 'Sahara occidentale',
    ER: 'Eritrea',
    ES: 'Spagna',
    ET: 'Etiopia',
    FI: 'Finlandia',
    FJ: 'Figi',
    FK: 'Isole Falkland',
    FM: 'Micronesia',
    FO: 'Isole Faroe',
    FR: 'Francia',
    GA: 'Gabon',
    GB: 'Regno Unito',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'Guiana francese',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibilterra',
    GL: 'Groenlandia',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Guinea Equatoriale',
    GR: 'Grecia',
    GS: 'Così. Georgia e So. Sandwich Isl.',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong (Cina)',
    HM: 'Heard e McDonald Islands',
    HN: 'Honduras',
    HR: 'Croazia',
    HT: 'Haiti',
    HU: 'Ungheria',
    IC: 'isole Canarie',
    ID: 'Indonesia',
    IE: 'Irlanda',
    IL: 'Israele',
    IM: 'Isola di Man',
    IN: 'India',
    IO: "Territorio britannico dell'Oceano Indiano",
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Islanda',
    IT: 'Italia',
    JE: 'Jersey',
    JM: 'Giamaica',
    JO: 'Giordania',
    JP: 'Giappone',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KH: 'Cambogia',
    KI: 'Kiribati',
    KM: 'Comore',
    KN: 'Saint Kitts e Nevis',
    KP: 'Corea del Nord',
    KR: 'Corea del Sud',
    KW: 'Kuwait',
    KY: 'Isole Cayman',
    KZ: 'Kazakistan',
    LA: 'Laos',
    LB: 'Libano',
    LC: 'St. Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lituania',
    LU: 'Lussemburgo',
    LV: 'Lettonia',
    LY: 'Libia',
    MA: 'Marocco',
    MC: 'Monaco',
    MD: 'Moldavia',
    ME: 'Montenegro',
    MF: 'San Martino',
    MG: 'Madagascar',
    MH: 'Isole Marshall',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Birmania',
    MN: 'Mongolia',
    MO: 'Macau (Cina)',
    MP: 'Isole Marianne settentrionali',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldive',
    MW: 'Malawi',
    MX: 'Messico',
    MY: 'Malesia',
    MZ: 'Mozambico',
    NA: 'Namibia',
    NC: 'Nuova Caledonia',
    NE: 'Niger',
    NF: 'Isola Norfolk',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Paesi Bassi',
    NO: 'Norvegia',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nuova Zelanda',
    OM: 'Oman',
    PA: 'Panamá',
    PE: 'Perù',
    PF: 'Polinesia francese',
    PG: 'Papua Nuova Guinea',
    PH: 'Filippine',
    PK: 'Pakistan',
    PL: 'Polonia',
    PM: 'St. Pierre e Miquelon',
    PN: 'Isole Pitcairn',
    PR: 'Porto Rico',
    PS: 'territori palestinesi',
    PT: 'Portogallo',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Riunione',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Ruanda',
    SA: 'Arabia Saudita',
    SB: 'Isole Salomone',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Svezia',
    SG: 'Singapore',
    SH: "Sant'Elena",
    SI: 'Slovenia',
    SJ: 'Svalbard e Jan Mayen',
    SK: 'Slovacchia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'Sudan del Sud',
    ST: 'São Tomé e Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Siria',
    SZ: 'Swaziland',
    TA: 'Tristan da Cunha',
    TC: 'Isole Turks e Caicos',
    TD: 'Ciad',
    TF: 'Territori della Francia del sud',
    TG: 'Togo',
    TH: 'Thailandia',
    TJ: 'Tagikistan',
    TK: 'Tokelau',
    TL: 'Timor Est',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turchia',
    TT: 'Trinidad e Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ucraina',
    UG: 'Uganda',
    UM: 'Isole periferiche degli Stati Uniti',
    US: 'Stati Uniti d’America',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Città del Vaticano',
    VC: 'Saint Vincent e Grenadine',
    VE: 'Venezuela',
    VG: 'Isole Vergini Britanniche',
    VI: 'Isole Vergini americane',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis e Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'Sudafrica',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },

  ja: {
    AC: 'アセンション島',
    AD: 'アンドラ',
    AE: 'アラブしゅちょうこくれんぽう',
    AF: 'アフガニスタン',
    AG: 'アンティグアバーブーダ',
    AI: 'アンギラ',
    AL: 'アルバニア',
    AM: 'アルメニア',
    AO: 'アンゴラ',
    AQ: 'なんきょくたいりく',
    AR: 'アルゼンチン',
    AS: 'アメリカ領サモア',
    AT: 'オーストリア',
    AU: 'オーストラリア',
    AW: 'アルバ',
    AX: 'オーランド諸島',
    AZ: 'アゼルバイジャン',
    BA: 'ボスニヤ・ヘルツェゴビナ',
    BB: 'バルバドス',
    BD: 'バングラデシュ',
    BE: 'ベルギー',
    BF: 'ブルキナファソ',
    BG: 'ブルガリア',
    BH: 'バーレーン',
    BI: 'ブルンジ',
    BJ: 'ベナン',
    BL: 'サン・バルテルミー',
    BM: 'バミューダ',
    BN: 'ブルネイ',
    BO: 'ボリビア',
    BQ: 'カリブ海オランダ',
    BR: 'ブラジル',
    BS: 'バハマ',
    BT: 'ブータン',
    BV: 'ブーベ島',
    BW: 'ボツワナ',
    BY: 'ベラルーシ',
    BZ: 'ベリーズ',
    CA: 'カナダ',
    CC: 'ココス（キーリング）諸島',
    CD: 'コンゴ-キンシャサ',
    CF: 'ちゅうおうアフリカ',
    CG: 'コンゴ-ブラザビル',
    CH: 'スイス',
    CI: 'コートジボワール',
    CK: 'クック諸島',
    CL: 'チリ',
    CM: 'カメルーン',
    CN: 'ちゅうごく',
    CO: 'コロンビア',
    CP: 'クリッパートン島',
    CR: 'コスタリカ',
    CU: 'キューバ',
    CV: 'カーボベルデ',
    CW: 'キュラソー',
    CX: 'クリスマス島',
    CY: 'キプロス',
    CZ: 'チェコ',
    DE: 'ドイツ',
    DG: 'ディエゴ・ガルシア',
    DJ: 'ジブチ',
    DK: 'デンマーク',
    DM: 'ドミニカ国',
    DO: 'ドミニカきょうわこく',
    DZ: 'アルジェリア',
    EA: 'セウタとメリリャ',
    EC: 'エクアドル',
    EE: 'エストニア',
    EG: 'エジプト',
    EH: '西サハラ',
    ER: 'エリトリア',
    ES: 'スペイン',
    ET: 'エチオピア',
    FI: 'フィンランド',
    FJ: 'フィジー',
    FK: 'フォークランド諸島',
    FM: 'ミクロネシア',
    FO: 'フェロー諸島',
    FR: 'フランス',
    GA: 'ガボン',
    GB: 'イギリス',
    GD: 'グレナダ',
    GE: 'ジョージア',
    GF: 'フランス領ギアナ',
    GG: 'ガーンジー',
    GH: 'ガーナ',
    GI: 'ジブラルタル',
    GL: 'グリーンランド',
    GM: 'ガンビア',
    GN: 'ギニア',
    GP: 'グアドループ',
    GQ: 'せきどうギニア',
    GR: 'ギリシャ',
    GS: 'そう。 ジョージア州 サンドイッチ島',
    GT: 'グアテマラ',
    GU: 'グアム',
    GW: 'ギニアビサウ',
    GY: 'ガイヤナ',
    HK: 'ほんこん',
    HM: 'ハード島とマクドナルド諸島',
    HN: 'ホンジェラス',
    HR: 'クロアチア',
    HT: 'ハイチ',
    HU: 'ハンガリー',
    IC: 'カナリア諸島',
    ID: 'インドネシア',
    IE: 'アイルランド',
    IL: 'イスラエル',
    IM: 'マン島',
    IN: 'インド',
    IO: 'イギリス領インド洋地域',
    IQ: 'イラク',
    IR: 'イラン',
    IS: 'アイスランド',
    IT: 'イタリア',
    JE: 'ジャージー',
    JM: 'ジャマイカ',
    JO: 'ヨルダン',
    JP: '日本語',
    KE: 'ケニア',
    KG: 'キルギスタン',
    KH: 'カンボジア',
    KI: 'キリバス',
    KM: 'コモロ',
    KN: 'セントクリストファー・ネイビス',
    KP: 'きたちょうせん',
    KR: 'かんこく',
    KW: 'クウェート',
    KY: 'ケイマン諸島',
    KZ: 'カザフスタン',
    LA: 'ラオス',
    LB: 'レバノン',
    LC: 'セントルシア',
    LI: 'リヒテンシュタイン',
    LK: 'スリランカ',
    LR: 'リベリア',
    LS: 'レソト',
    LT: 'リトアニア',
    LU: 'ルクセンブルク',
    LV: 'ラトビア',
    LY: 'リビア',
    MA: 'モロッコ',
    MC: 'モナコ',
    MD: 'モルドバ',
    ME: 'モンテネグロ',
    MF: 'セントマーティン',
    MG: 'マダガスカル',
    MH: 'マーシャル諸島',
    MK: 'マケドニア',
    ML: 'マリ',
    MM: 'ミャンマー',
    MN: 'モンゴル',
    MO: 'マカオ',
    MP: '北マリアナ諸島',
    MQ: 'マルティニーク',
    MR: 'モーリタニア',
    MS: 'モントセラト',
    MT: 'マルタ',
    MU: 'モーリシャス',
    MV: 'モルディブ',
    MW: 'マラウィ',
    MX: 'メキシコ',
    MY: 'マレーシア',
    MZ: 'モザンビーク',
    NA: 'ナミビア',
    NC: 'ニューカレドニア',
    NE: 'ニジェール',
    NF: 'ノーフォーク島',
    NG: 'ナイジェリア',
    NI: 'ニカラグア',
    NL: 'オランダ',
    NO: 'ノルウェー',
    NP: 'ネパール',
    NR: 'ナウル',
    NU: 'ニウエ',
    NZ: 'ニュージーランド',
    OM: 'オーマン',
    PA: 'パナマ',
    PE: 'ペルー',
    PF: 'フランス領ポリネシア',
    PG: 'パプアニューギニア',
    PH: 'フィリピン',
    PK: 'パキスタン',
    PL: 'ポーランド',
    PM: 'サンピエール＆ミクロン',
    PN: 'ピトケアン諸島',
    PR: 'プエルトリコ',
    PS: 'パレスチナ領土',
    PT: 'ポルトガル',
    PW: 'パラオ',
    PY: 'パラグアイ',
    QA: 'カタール',
    RE: '再会',
    RO: 'ルーマニア',
    RS: 'セルビア',
    RU: 'ロシア',
    RW: 'ルワンダ',
    SA: 'サウジアラビア',
    SB: 'ソロモン諸島',
    SC: 'セイシェル',
    SD: 'スーダン',
    SE: 'スウェーデン',
    SG: 'シンガポール',
    SH: 'セントヘレナ',
    SI: 'スロベニア',
    SJ: 'スバールバル諸島とヤン・マイエン',
    SK: 'スロバキア',
    SL: 'シエラレオネ',
    SM: 'サンマリノ',
    SN: 'セネガル',
    SO: 'ソマリア',
    SR: 'スリナム',
    SS: '南スーダン',
    ST: 'サントメ・プリンシペ',
    SV: 'エルサルバドル',
    SX: 'シント・マールテン',
    SY: 'シリア',
    SZ: 'スワジランド',
    TA: 'トリスタンダクーニャ',
    TC: 'タークスカイコス諸島',
    TD: 'チャド',
    TF: 'フランス南部領',
    TG: 'トーゴ',
    TH: 'タイ',
    TJ: 'タジキスタン',
    TK: 'トケラウ',
    TL: '東ティモール',
    TM: 'トルクメニスタン',
    TN: 'チュニジア',
    TO: 'トンガ',
    TR: 'トルコ',
    TT: 'トリニダード・トバゴ',
    TV: 'ツバル',
    TW: 'たいわん',
    TZ: 'タンザニア',
    UA: 'クライナ',
    UG: 'ウガンダ',
    UM: '米国の離島',
    US: 'アメリカ',
    UY: 'ウルグアイ',
    UZ: 'ウズベキスタン',
    VA: 'バチカン',
    VC: 'セントビンセントおよびグレナディーン諸島',
    VE: 'ベネズエラ',
    VG: 'イギリス領ヴァージン諸島',
    VI: 'アメリカ領ヴァージン諸島',
    VN: 'ベトナム',
    VU: 'バヌアツ',
    WF: 'ウォリス・フツナ',
    WS: 'サモア',
    XK: 'コソボ',
    YE: 'イエメン',
    YT: 'マヨット',
    ZA: 'みなみアフリカ',
    ZM: 'ザンビア',
    ZW: 'ジンバブエ',
  },
}
